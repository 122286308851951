import React from 'react'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import SEO from '../../../components/SEO'
import '../../../styles/sdn-whitepaper.less'
import { EbookPageForm } from '../../../components/form-biz'
const loadText = require('src/utils').loadText
import { lang } from '../../../utils'
const data = loadText('sdn-whitepaper')
const TopBanner = () => (
  <div className="sdn-whitepaper-topBanner">
    <div className="sdn-whitepaper-topBanner-bg">
      <div className="headerContainer ">
        <Header logo="/logo-blue.svg" />
      </div>
      <div className="sdn-whitepaper-topBanner-content">
        <div className="left">
          <div className="title">{data.topBanner.title}</div>
          <div className="subtitle">{data.topBanner.subtitle}</div>
        </div>
        <div className="right">
          <img src="/sdn-whitepaper/topBanner-pic1.svg" alt="icon" />
          <img
            src="/sdn-whitepaper/topBanner-pic2.png"
            alt="man"
            className="man"
          />
        </div>
      </div>
    </div>
  </div>
)
const Form = () => (
  <div className="sdn-whitepaper-form">
    <div className="sdn-whitepaper-form-bg">
      <div className="sdn-whitepaper-form-content">
        <div className="left">
          {data.form.content.paragraphs.map((p) => (
            <div>{p}</div>
          ))}
          <ul>
            {data.form.content.list.map((l) => (
              <li>{l}</li>
            ))}
          </ul>
        </div>
        <div className="right">
          <EbookPageForm
            // btnText="Read the whitepaper"
            source="sdn/white-paper"
            onSuccess={() => {
              const link = document.createElement('a')
              const file =
                lang === 'zh'
                  ? 'Cloud Networking技术白皮书.pdf'
                  : 'Cloud Networking White Paper 2022.pdf'
              link.href = `/${file}`
              link.download = file
              link.dispatchEvent(new MouseEvent('click'))
            }}
          />
        </div>
      </div>
    </div>
  </div>
)

export default function Home() {
  return (
    <div className="sdn-whitepaper">
      <SEO
        {...data.seo}
        featuredImage="/featuredImage/zenlayer.jpg"
        noIndex={true}
      />
      <TopBanner />
      <Form />
      <Footer />
    </div>
  )
}
